import React from 'react';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import { theme as themeConst } from '../../styles/theme-constants';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    color: 'white',
    height: '52px',
    fontWeight: '700',
    padding: '0 40px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    fontSize: '18px',
  },
  validButton: {
    backgroundColor: themeConst.primary,
    '&:hover': {
      backgroundColor: themeConst.white,
      border:`1px solid ${themeConst.primary}`,
      color:themeConst.primary
    },
  },
  disabledButton: {
    backgroundColor: "#D9D8DF",
    color:"#4A4A7C",
    '&:hover': {
      backgroundColor: "#D9D8DF",
      color:"#4A4A7C",
    },
  },
  floatingBackground: {
    background: 'rgba(0,0,0,0.3)', 
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '-100%',
    transition: `left 2000ms ${theme.transitions.easing.easeInOut}`
  },
  floatingText: {
    zIndex: 10,
    position: 'absolute',
    transform: 'translateY(0px)',
    textTransform: 'capitalize',
    transition: `transform 1000ms ${theme.transitions.easing.easeInOut}`
  },
  floatingIcon: {
    position: 'absolute',
    zIndex: 10,
    position: 'relative',
    bottom: '-100%',
    transform: 'translateY(100px)',
    transition: `transform 1000ms ${theme.transitions.easing.easeInOut}`
  },
}));


export function LoginButton({
  isFormValid,
  isSuccess,
  loadingProgress,
}) {

  const classes = useStyles();

  const returnLeftPosition = (percentage) => {
    return `${-100 + percentage}%`; 
  }

  return (
    <Button
      className={clsx(classes.button, isFormValid ? classes.validButton : classes.disabledButton)}
      size="large"
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
    >
      <span className={classes.floatingText}
        style={{ transform: isSuccess ? 'translateY(-100px)' : 'translateY(0px)' }}
      >ingresar</span>
      <span className={classes.floatingIcon}
        style={{ transform: isSuccess ? 'translateY(5px)' : 'translateY(100px)' }}
      ><CheckIcon /></span>
      <div className={clsx(classes.floatingBackground)}
        style={{ left: returnLeftPosition(loadingProgress) }}></div>
    </Button>
  );
}