import { Divider, Grid, Snackbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from 'formik';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import Copyright from "../components/Copyright";
import { LoginForm } from "../components/Forms/LoginForm";
import { schema } from "../components/Forms/loginFormik";
import LoadingLayout from "../components/LoadingLayout";
import LoginModal from "../components/LoginModal";
import { LogoLogin } from "../components/logo";
import { Section } from "../components/section";
import { isLoading, resetLoginState, setChangePasswordModal, setErrorAtLogin } from '../context/actions';
import { store } from '../context/store';
import { getLogoCompanyByqueryParams } from '../lib/collections';
import { getAnalyticId, removeCookies } from '../lib/cookies';
import { hash } from '../lib/hash';
import { handleLoginResponse, login } from '../lib/users';
import { theme as themeStyles } from "../styles/theme-constants";
import { trackLogOut } from '../tracking/users';

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 500,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingTop: '2rem'
  },
  textInputs: {
    fontSize: '22px',
  },
  errorline: {
    color: themeStyles.error,
    marginTop: '0px'
  },
  label: {
    color: '#333',
    fontSize: '18px',
  },
  showPassword: {
    color: themeStyles.primary,
    fontSize: 'small'
  },
  myAlert: {
    position: "absolute",
    zIndex: "10",
  },
  mySnackbar: {
    width: "45%",
    bottom: "17%",
  }
});

function SignIn(props) {
  const { state, dispatch } = useContext(store);
  const {
    authReducer: { error, showChangePasswordModal },
    applicationReducer: {
      loginProgress,
      isLoginSuccessfull,
    }
  } = state;

  const { classes, redirect } = props;
  //Show password input
  const [showPassword, setShowPassword] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberme: false,
    },
    validationSchema: schema,
    onSubmit: () => {
      handleLogin({
        email: formik.values.email.trim(),
        password: formik.values.password.trim(),
        rememberMe: formik.values.rememberme
      });
    },
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsIE(false);
  }
  useEffect(() => {
    setIsIE(/MSIE|Trident/.test(window.navigator.userAgent));
    // HACK - A LOGED IN USER MUST NEVER GET TO LOGIN
    getAnalyticId() && trackLogOut();
    // HACK - A LOGED IN USER MUST NEVER GET TO LOGIN
    resetLoginState(dispatch);
  }, []);

  async function handleLogin(values) {

    //clean session whatsApp
    sessionStorage.removeItem('time');
    localStorage.clear();

    isLoading(dispatch, { loading: true });
    setErrorAtLogin(dispatch, false);
    const loginInfo = {
      identifier: values.email.trim(),
      password: hash(values.password),
    };

    const response = await login(dispatch, loginInfo);
    handleLoginResponse(dispatch, {
      ...response,
      data: { ...response.data, maxAge: values.rememberMe, redirect }
    }, loginInfo.identifier);
  }

  const closeModal = () => {
    setChangePasswordModal(dispatch, false);
  }

  return (
    <main className={classes.main}>
      <CssBaseline />

      <LoadingLayout isLoading={loginProgress} />
      <Section className={classes.paper}>
          <LogoLogin noMargin logoCompany={props.logo}/>
        <Typography component="h1" variant="h5">
          Entra a tu cuenta
        </Typography>
        <LoginForm
          classes={classes}
          error={error}
          isLoginSuccessfull={isLoginSuccessfull}
          loginProgress={loginProgress}
          formik={formik}
          showPassword={showPassword}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
        />
        <Grid container justifyContent="center">
          <Grid item>
            <Link href="/forgot-password" color='secondary' variant="subtitle2" fontSize="10" >
              <i>¿Olvidaste tu contraseña?</i>
            </Link>
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider light variant="fullWidth" />
        <br />
        <Grid container justifyContent="center">
          <Grid item>
            ¿No tienes cuenta?{` `}
            <Link color="secondary" href="/signup" variant="body1">
              Regístrate aquí
            </Link>
          </Grid>
        </Grid>
        <LoginModal open={showChangePasswordModal} closeModal={closeModal} username={formik.values.email} />
        <Snackbar className={classes.mySnackbar} open={isIE} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} variant="filled" className={classes.myAlert} severity="info">
          Para conseguir la mejor experiencia de uso, accede a Vinco desde el navegador Google Chrome.
          </Alert>
        </Snackbar>
      </Section>
      <Box mt={5}>
        <Copyright />
      </Box>
    </main>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export async function getServerSideProps(ctx) {
  
  // always login
  removeCookies(ctx);
  
  //get logo company
  var id = ctx.query.ref
  const redirect = ctx.query.redirect 
  const response = await getLogoCompanyByqueryParams(id)
    return {
      props: {
        logo: response,
        redirect: redirect || null
      }
    };

}

export default withStyles(styles)(SignIn);
