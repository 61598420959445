import { LoginButton } from "./LoginButton";
import { theme as themeStyles } from "../../styles/theme-constants";
import { ThemeStyles } from "../themeStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField, InputAdornment, IconButton, withStyles } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button } from "../../vinco-components";

const CssTextField = withStyles({
  root: {
    borderRadius: '5px',
    backgroundColor: '#f5f5f5',
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#f5f5f5',
      },
      '&:hover fieldset': {
        borderColor: '#f5f5f5',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

export const LoginForm = ({
  classes,
  error,
  isLoginSuccessfull,
  loginProgress,
  formik,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword
}) => {

  const onEnterKeyPress = (e) => {
    if (e.keyCode == 13) {
      () => formik.handleSubmit;
    }
  }

  return (
    <>
      <form id={'loginform'} className={classes.form} onSubmit={formik.handleSubmit}>
        <ThemeStyles color={themeStyles.secondary}>
          <span className={classes.label}>Correo</span>
          <CssTextField 
            required
            fullWidth
            id="validation-outlined-input"
            name="email"
            autoComplete="@gmail.com"
            variant="outlined"
            placeholder="Correo"
            onChange={formik.handleChange}
            value={formik.values.email}
            type="text"
            onKeyDown={(e) => onEnterKeyPress(e)}
            onBlur={formik.handleBlur}
            className={classes.margin}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <p className={classes.errorline}>{formik.touched.email && formik.errors.email}</p>
          <span className={classes.label}>Contraseña</span>
          <CssTextField
            required
            fullWidth
            id="validation-outlined-input-pass"
            name="password"
            variant="outlined"
            placeholder="Contraseña"
            onChange={formik.handleChange}
            value={formik.values.password}
            type={showPassword ? "text" : "password"}
            onKeyDown={(e) => onEnterKeyPress(e)}
            onBlur={formik.handleBlur}
            className={classes.margin}
            error={formik.touched.password && Boolean(formik.errors.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                    <span className={classes.showPassword}>
                        {showPassword ? "Ocultar" : "Mostrar"}
                    </span>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className={classes.errorline}>{formik.touched.password && formik.errors.password}</p>
          <FormControlLabel
            control={
              <Checkbox
                value="rememberme"
                name="rememberme"
                id="rememberme"
                color="primary"
                onChange={formik.handleChange}
              />
            }
            label="Recuérdame"
          />
        </ThemeStyles>
        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}
        <div style={{width:'100%'}}>
          <Button type={'submit'} IsDisabled={!formik.isValid} label={'Ingresar'}/>
        </div>
        {/* <LoginButton
          isFormValid={formik.isValid}
          isSuccess={isLoginSuccessfull}
          loadingProgress={loginProgress}
        /> */}
      </form>
    </>
  );
};
